import React from "react"
import "../../styles/mindbody.scss"
import accountStyles from "../../components/account.module.scss"
import Layout from '../../components/layout-team-access'

const Account = () => (
  <Layout
    title="My Account | RF Dance"
    description="Access your RF Dance online to make updates, purchases classes, and more." 
    pathname="account"
    noHero="no-hero">
    <div className="container">
      <iframe 
        className={accountStyles.iframe}
        title="Account login"
        id="mindbody_branded_web_cart_modal" 
        // onLoad="window.send_information_to_mindbody_for_great_justice('mindbody_branded_web_cart_modal')" 
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        scrolling="yes"
        src="https://widgets.healcode.com/sites/62245/client/schedules" />
    </div>
  </Layout>
)

export default Account